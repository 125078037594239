import React, { useState, useEffect } from "react";
import { Table, Avatar, Inputs, ClientStatus, Pagination } from "../components";
import { download } from "../utils";
import { withStuff } from "../hocs";

const ClientIndex = ({ state, header = "Clients", search = false, pagination = true }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [status, setStatus] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(25);
  const [order, setOrder] = useState({
    field: "created_at",
    order: "desc",
  });

  const clients = state.clients || [];
  const totalCount = clients?.totalCount;

  useEffect(() => {
    const params = {
      pageSize: limit,
      currentPage: page,
      ...order,
    };
    if (searchTerm) params.search = searchTerm;
    if (status) params.status = status;

    api.getClients(params);
  }, []);

  useEffect(() => {
    const params = {
      pageSize: limit,
      currentPage: page,
      ...order,
    };
    if (searchTerm) params.search = searchTerm;
    if (status) params.status = status;

    api.getClients(params);
  }, [status, page, searchTerm, order]);

  useEffect(() => {
    setPage(1);
    const params = {
      pageSize: limit,
      currentPage: 1,
    };
    if (searchTerm) params.search = searchTerm;
    if (status) params.status = status;

    api.getClients(params);
  }, [limit]);

  const schema = {
    name: {
      label: "Name",
      descending: order.order === "desc",
      onClick: () => {
        setOrder({
          field: "last_name",
          order: order.order === "desc" ? "asc" : "desc",
        });
      },
      children: ({ datum }) => {
        return (
          <a
            className="flex justify-start space-x-2 items-center cursor-pointer no-underline"
            href={`/clients/${datum.id}`}
          >
            <div className="lg:block hidden" style={{ width: "33px !important" }}>
              <Avatar
                user={datum}
                size={32}
                onClick={() => (window.location.href = `/clients/${datum.id}`)}
              />
            </div>
            <div>
              <div className="text-move-blue text-md font-semibold">{datum.name}</div>
            </div>
          </a>
        );
      },
    },
    client_request_type: {
      label: "Coverage",
      descending: order.order === "desc",
      onClick: () => {
        setOrder({
          field: "client_request_type",
          order: order.order === "desc" ? "asc" : "desc",
        });
      },
    },
    referred_at: {
      label: "Referred",
      defaultSort: true,
      descending: order.order === "desc",
      onClick: () => {
        setOrder({
          field: "created_at",
          order: order.order === "desc" ? "asc" : "desc",
        });
      },
      children: ({ datum }) => {
        const date = new Date(datum.referred_at);
        return (
          <div>
            {date.toLocaleString("en-US", {
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </div>
        );
      },
    },
    health_advisor: {
      label: "Agent",
    },
    financial_advisor: {
      label: "Advisor",
    },
    status: {
      label: "Status",
      descending: order.order === "desc",
      onClick: () => {
        setOrder({
          field: "status",
          order: order.order === "desc" ? "asc" : "desc",
        });
      },
      children: ({ datum }) => {
        return (
          <>
            <div className="flex justify-between items-center">
              <ClientStatus status={datum.status} />
              <a
                className="text-red-500 hover:text-red-700 cursor-pointer ml-2"
                onClick={() => {
                  if (window.confirm("Are you sure you want to delete this client?"))
                    api.destroyClient(datum.id);
                }}
              >
                <span className="text-xs">
                  <i className="fa-solid fa-x" />
                </span>
              </a>
            </div>
          </>
        );
      },
    },
  };

  if (!state.user.super_admin) {
    delete schema.health_advisor;
  }

  if (!state.user.admin) {
    delete schema.financial_advisor;
  }

  //   if (!search && count === 0) {
  //     return null;
  //   }

  async function downloadClientCsv(e) {
    e.stopPropagation();
    const url = await api.getClientCsv({ search: searchTerm, status });
    download(url, `clients-${Date.now()}.csv`);
  }

  const handlePageChange = (page) => {
    console.log("page", page);
    setPage(page);
  };

  return (
    <div className="rounded-md shadow-sm bg-move-lgrey p-3 grid gap-4">
      <div className="flex justify-between items-start">
        <span className="text-lg font-medium">{header}</span>
        <div className="flex gap-3">
          {search && (
            <a
              className="rounded-md bg-move-teal text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2"
              onClick={downloadClientCsv}
            >
              <button type="button">
                <i className="fa-solid fa-file-arrow-down mr-2"></i>Download
              </button>
            </a>
          )}
          <a
            className="rounded-md bg-move-orange text-move-white text-sm font-bold hover:shadow-md hover:text-move-lslate px-3 py-2"
            href="/clients/new"
          >
            <button type="button">
              <i className="fa-solid fa-circle-plus mr-2"></i>Refer
            </button>
          </a>
        </div>
      </div>
      {search && (
        <Inputs
          inputs={[
            {
              type: "text",
              name: "search",
              placeholder: "Search",
              value: searchTerm,
              style: { marginTop: "0px" },
              onChange: (e) => {
                setSearchTerm(e.target.value);
              },
            },
            {
              type: "select",
              name: "status",
              placeholder: "Filter Status",
              capitalizeOptions: true,
              allowBlank: true,
              options: [
                "referred",
                "scheduled",
                "pending",
                "enrolled",
                "cancelled",
                "deferred",
                "passed",
                "renewal",
                "terminated",
                "unknown",
                "follow up",
              ],
              value: status,
              onChange: (e) => {
                setStatus(e.target.value);
              },
            },
          ]}
        />
      )}
      <Table schema={schema} data={state.clients || []} paginated={true} />
      {pagination && (
        <Pagination
          currentPage={page}
          totalCount={totalCount}
          pageSize={limit}
          onPageChange={handlePageChange}
          onPageSizeChange={(newPageSize) => setLimit(newPageSize)}
        />
      )}
      {totalCount == limit && (
        <div className="text-center">
          <a className="btn-white" href="/clients">
            View All
          </a>
        </div>
      )}
    </div>
  );
};

export default withStuff(ClientIndex, {
  state: true,
  api: true,
  effect: async ({ api, limit }) => {
    api.getClients({ limit });
  },
});
