import React, { useEffect } from "react";
import { withStuff } from "../hocs";
import { parseTimeStamp } from "../utils";
import { TodoIndex } from "../components";

const Booking = ({ state, api, clientId }) => {
  async function destroyBooking(reschedule = false) {
    const res = await api.destroyBooking(state.booking.uid);

    if (res) {
      if (reschedule) {
        window.location.href = `/schedule/${clientId || ""}`;
      }
    }
  }

  // Removed for now until we figure out the scheduling flow/issues
  /* async function updateClientStatus(status) {
    const result = await api.updateClient(clientId || state?.client?.id || state?.user?.id, {
      status: status,
    });

    if (result) {
      window.location.reload();
    } else {
      console.log("Failed to update client status");
    }
  }

  useEffect(() => {
    if (
      !state.booking &&
      (state?.client?.status === "scheduled" || state?.user?.status === "scheduled")
    ) {
      updateClientStatus("referred");
    }
  }, [state.booking, state?.client?.status, state?.user?.status]); */

  const PersonalProfile = () => {
    return (
      <TodoIndex
        todos={[
          {
            header: "Complete your personal profile",
            href: "/survey",
            color: "orange",
            text: "white",
          },
        ]}
      />
    );
  };

  if (!state.booking) {
    return (
      <TodoIndex
        todos={[
          {
            copy: clientId
              ? `Book a health insurance appointment with ${state.client.name}`
              : " Book a health insurance appointment with Move Health",
            header: "Schedule appointment",
            href: `/schedule/${clientId || ""}`,
            color: "orange",
            text: "white",
          },
        ]}
      />
    );
  } else if (state.booking.status === "CANCELLED") {
    return (
      <TodoIndex
        todos={[
          {
            copy: clientId
              ? `The scheduled meeting was cancelled. Book another meeting with ${state.client.name}`
              : " The scheduled meeting was cancelled. Book another health insurance appointment with Move Health",
            header: "Schedule appointment",
            href: `/schedule/${clientId || ""}`,
            color: "orange",
            text: "white",
          },
        ]}
      />
    );
  } else if (
    state.booking.status === "ACCEPTED" &&
    Date.parse(state.booking.startTime) < new Date().getTime()
  ) {
    return (
      <>
        {!state.user.survey_completed && !clientId && <PersonalProfile />}
        <TodoIndex
          todos={[
            {
              copy: clientId
                ? `The scheduled meeting has passed. Book another meeting with ${state.client.name}`
                : " The scheduled meeting has passed. Book another health insurance appointment with Move Health",
              header: "Schedule appointment",
              href: `/schedule/${clientId || ""}`,
              color: "orange",
              text: "white",
            },
          ]}
        />
      </>
    );
  }

  return (
    <>
      {!state.user.survey_completed && !clientId && <PersonalProfile />}
      <div className="rounded p-3 mb-4 bg-move-lyellow">
        <div className="text-lg font-semibold">Upcoming Meeting</div>
        <div className="text-base wrap my-2">
          {clientId ? "A" : "Your a"}ppointment is scheduled for{" "}
          {parseTimeStamp(state.booking.startTime)}.
        </div>
        <div className="flex items-start gap-3">
          <a
            href="#"
            onClick={() => destroyBooking(true)}
            className="block no-underline text-move-blue"
          >
            Reschedule
          </a>
          <a
            href="#"
            onClick={() => {
              if (window.confirm("Are you sure you want to cancel your appointment?")) {
                destroyBooking();
              }
            }}
            className="block no-underline text-move-red"
          >
            Cancel
          </a>
        </div>
      </div>
    </>
  );
};

export default withStuff(Booking, {
  api: true,
  state: true,
  loader: "bookings",
  effect: ({ clientId }) => {
    api.getBooking(clientId);
  },
});
